<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Providers
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-switch v-model="hasNegativeKeywords" label="Has negative keywords"></v-switch>
          </v-col>
          <v-col>
            <v-switch v-model="isProtected" label="Is protected"></v-switch>
          </v-col>
          <v-col>
            <v-text-field v-model="nameSearch" label="Name" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.is_protected`]="{ item }">
        <v-icon v-if="item.is_protected" small>done</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Provider', params: { providerID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'ProvidersTable',

  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '# Neg KWs', value: 'nkw_keywords_count' },
        { text: 'Protected', value: 'is_protected' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'providers_options',
      lsFilters: 'providers_filters',
      dataEndpoint: '/a/core/providers',
      hasNegativeKeywords: true,
      isProtected: false,
      nameSearch: '',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // exclude None
      myURL += '&id=-0'

      // filter has NKWs
      if (this.hasNegativeKeywords) {
        myURL += '&nkw_keywords_count=>0'
      }

      // filter is protected
      if (this.isProtected) {
        myURL += '&is_protected=' + this.isProtected
      }

      // filter name search
      if (this.nameSearch) {
        myURL += '&name=~' + this.nameSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
     let filterObj = {
        'hasNegativeKeywords': this.hasNegativeKeywords,
        'isProtected': this.isProtected,
        'nameSearch': this.nameSearch
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['hasNegativeKeywords']) { this.hasNegativeKeywords = filters['hasNegativeKeywords'] }
      if (filters['isProtected']) { this.isProtected = filters['isProtected'] }
      if (filters['nameSearch']) { this.nameSearch = filters['nameSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
